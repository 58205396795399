

















/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean

  loading = false
  area = ''

  closeDialog() {
    this.$emit('close', '')
  }
  dialogConfirm() {
    if (this.area === '') {
      this.$message.warning('请先输入区域')
    } else {
      this.loading = true
      this.$api.common
        .addArea({
          phase: this.area
        })
        .then((res: any) => {
          const data = res.data
          this.loading = false
          if (data.success) {
            this.$message.success('新增成功')
            this.$emit('optSuccess')
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
