





















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import AddAreaModal from './addArea-modal.vue'
import { Select, Option, Checkbox, CheckboxGroup } from 'element-ui'
import { CommonModule } from '@/store/modules/common'
Vue.use(Select)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

@Component({
  components: {
    AddAreaModal
  }
})
export default class extends Vue {
  homeInfo: any = {
    phase: '',
    buildingNo: '',
    remark: '',
    doors: []
  }
  isHover = false
  checkAll = false
  isShowDialog = false
  saveLoading = false

  // 获取房屋期数
  get houseAreas() {
    return CommonModule.houseAreas
  }

  // 获取设备组数据
  get deviceGroups() {
    return CommonModule.deviceGroups
  }

  async beforeCreate() {
    CommonModule.GetHouseAreas()
    CommonModule.GetDeviceGroups()
  }
  created() {
    if (this.$route.query.id) {
      this.getHouseDetail()
    }
  }

  // 获取房屋的详情
  getHouseDetail() {
    this.saveLoading = true
    this.$api.resident
      .getHouseInfo(this.$route.query.id)
      .then((res: any) => {
        this.saveLoading = false
        if (res.data.success) {
          const info = res.data.data
          this.homeInfo = {
            phase: info.phase,
            buildingNo: info.buildingNo,
            remark: info.remark,
            doors: []
          }
          this.homeInfo.doors =
            info.houseDevices.map((v: any) => {
              return v.deviceGroupId
            }) || []
          this.checkAll =
            this.homeInfo.doors.length === this.deviceGroups.length
        }
      })
      .catch(() => {
        this.saveLoading = false
      })
  }
  // 保存(新增/编辑 房屋)
  save() {
    this.saveLoading = true
    if (this.$route.query.id) {
      // 编辑
      const editPrams = {
        houseId: this.$route.query.id,
        phase: this.homeInfo.phase,
        buildingNo: this.homeInfo.buildingNo,
        remark: this.homeInfo.remark,
        devices: this.homeInfo.doors
      }
      this.$api.resident
        .editHouse(editPrams)
        .then((res: any) => {
          this.saveLoading = false
          if (res.data.success) {
            this.$message.success('编辑成功')
            this.$router.push({
              path: '/residents/home-list'
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {
          this.saveLoading = false
        })
    } else {
      // 新增
      const p = {
        phase: this.homeInfo.phase,
        buildingNo: this.homeInfo.buildingNo,
        remark: this.homeInfo.remark,
        devices: this.homeInfo.doors
      }
      this.$api.resident
        .hansAddHouse(p)
        .then((res: any) => {
          const data = res.data
          if (data.success) {
            this.saveLoading = false
            this.$message.success('保存成功')
            this.$router.push({
              path: '/residents/home-list'
            })
          } else {
            this.saveLoading = false
            this.$message.error(data.msg)
          }
        })
        .catch(() => {
          this.saveLoading = false
        })
    }
  }

  handleCheckAllChange(val: boolean) {
    this.homeInfo.doors = val
      ? this.deviceGroups.map((v: any) => {
          return v.id
        })
      : []
  }

  handleCheckedDoorsChange(value: any) {
    const checkedCount = value.length
    this.checkAll = checkedCount === this.deviceGroups.length
  }

  // 返回
  goback() {
    this.$router.push('/residents/home-list')
  }

  // 关闭对话框
  closeDialog() {
    this.isShowDialog = false
  }
  // 操作对话框后统一回调
  afterOpt() {
    CommonModule.GetHouseAreas()
    this.closeDialog()
  }
}
